import React from 'react';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {IProductOption} from '@wix/wixstores-graphql-schema/dist/es/src';
import {IProductOptionSelectionItem} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {UserInputType} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';

export interface ProductColorsProps {
  options: IProductOption;
  allowMultiple?: boolean;
  error?: JSX.Element;
  onSelect?: Function;
  selected?: IProductOptionSelectionItem[];
  errorIndex: number;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductColors extends React.Component<
  ProductColorsProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  public readonly onSelect = (selected: IProductOptionSelectionItem[]): void => {
    /* istanbul ignore else: todo: test */
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };

  public readonly renderDescription = (): string => {
    const {selected} = this.props;

    if (!selected.length) {
      return null;
    }

    return `: ${selected.map((selection: IProductOptionSelectionItem) => selection.description).join(', ')}`;
  };

  public render(): JSX.Element {
    const {
      options: {title, selections},
      allowMultiple,
      selected,
      errorIndex,
      globals: {errorPlacement, isProductSubmitted, userInputErrors},
      t,
    } = this.props;

    return (
      <section>
        <div data-hook="product-colors-title" className={s.description}>
          {title}
          {this.renderDescription()}
        </div>
        <ErrorTooltipProvider
          appendTo={'scrollParent'}
          content={t('SELECT_OPTION_WARNING', {optionTitle: title})}
          placement={errorPlacement}
          show={isProductSubmitted && userInputErrors[UserInputType.Selection][errorIndex]}>
          <div className={s.actionable}>
            <ColorSampleGroup
              colorGroupClassName={s.colorSamplerGroup}
              allowMultiple={allowMultiple}
              onSelect={this.onSelect}
              options={selections as IProductOptionSelectionItem[]}
              selected={selected}
              colorSampleClassName={s.colorSampler}
            />
          </div>
        </ErrorTooltipProvider>
      </section>
    );
  }
}
