import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

export enum ProductPaymentBreakdownDataHook {
  ProductPaymentBreakdown = 'product-payment-breakdown',
}

@withGlobalProps
export class ProductPaymentBreakdown extends React.Component<ProvidedGlobalProps> {
  public render(): JSX.Element {
    return <div data-hook={ProductPaymentBreakdownDataHook.ProductPaymentBreakdown} />;
  }
}
